// Import Swiper and modules
import {
	Swiper,
	Navigation,
	Pagination,
	Scrollbar,
	Controller,
	Autoplay
} from '../../../config/node_modules/swiper/core';
Swiper.use([Navigation, Pagination, Scrollbar, Controller, Autoplay]);

const logoSlider = () => {
	var logoSliders =  document.querySelectorAll('.logo-slider__logos');
	if (typeof(logoSliders) != 'undefined' && logoSliders != null) {
		logoSliders.forEach((logoSlider) => {
			var slides = logoSlider.querySelectorAll('.swiper-slide');
			logoSlider = new Swiper ('.logo-slider__logos', {
				direction: 'horizontal',
				loop: true,
				speed: 8000,
				roundLengths: true,
				watchSlidesProgress: true,
				centeredSlides: true,
				allowTouchMove: false,
				autoplay: {
					delay: 1,
					disableOnInteraction: false,
				},
				slidesPerView: "auto",
				loopedSlides: slides.length,
			});
		});
	}
}

export {
	logoSlider,
}