async function accordion() {
    const accordion = document.querySelectorAll(".taxonomy__checkbox__wrapper .filter-title");
    accordion.forEach(e => {
        e.addEventListener("click", () => {
            let t = document.querySelectorAll(".taxonomy__checkbox__wrapper .form-field"),
                o = e.nextElementSibling;
            t.forEach(e => {
                e == o ? o.style.maxHeight ? o.style.maxHeight = null : o.style.maxHeight = o.scrollHeight + "px" : e.style.maxHeight = null
            }), accordion.forEach(t => {
                t == e ? t.classList.toggle("active") : t.classList.remove("active")
            });
        })
        if (e.classList.contains('active')) {
            e.nextElementSibling.style.maxHeight = e.nextElementSibling.scrollHeight + "px";
        }
    });
} export {
    accordion
}