import {debounce, throttle} from './functions';
var $ = require("../../../config/node_modules/jquery");

async function filter() {
	var selectedFilters = [];
	selectedFilters['page'] = 1;

	var textFilter = document.getElementById("text_search");
	var cityFilter = document.getElementById("city_search");
	var radiusFilter = document.getElementById("radius_search");
	var checkboxFilters = document.querySelectorAll('.filter-input input')

	var postType;
	var theEnd = false;
	var appended = false;
	var clickedLoadMore = false;
	var container = document.getElementById("result__wrapper");
	var i = 0;

	if ($(container).length > 0) {

		function makeRequest(data, type) {

			var text = [];
			var i;
			for (i in data) {
				if (data.hasOwnProperty(i)) {
					text.push(i + "=" + encodeURIComponent(data[i]));
				}
			}
			var textState = [];
			var i;
			for (i in data) {
				if (data.hasOwnProperty(i)) {
					if (i != 'page') {
						textState.push(i + "=" + encodeURIComponent(data[i]));
					}
				}
			}
			text = text.join("&");
			textState = textState.join("&");
			var url = "/filters?" + text;
			var pushStateUrl = "?" + textState;
			var loader = "<div class='loader'></div>";

			window.history.pushState(null, null, pushStateUrl);

			var xhr = window.XMLHttpRequest ? new XMLHttpRequest : window.ActiveXObject ? new ActiveXObject("Microsoft.XMLHTTP") : (alert("Browser does not support XMLHTTP."), false);
			xhr.onreadystatechange = text;
			$(container).append(loader);
			xhr.open("GET", url, true);
			xhr.setRequestHeader("Content-Type", "application/json");
			xhr.send(text);
			xhr.onload = function() {
				setTimeout(function() {
					container.classList.add("fade-out");
					if (type == 'append') {
						container.innerHTML = container.innerHTML + xhr.response;
						if (!xhr.response.includes('+==THEEND==+')) {
							setTimeout(function() {
								appended = false;
							}, 1000);
						} else {
							theEnd = true;
						}
					} else {
						container.innerHTML = xhr.response;
					}
					setTimeout(function() {
						$('.loader').remove();
						container.classList.remove('fade-out');
					}, 50);
				}, 100);
			};
			xhr.onerror = function() {
				alert("error");
			};
		}

		function loadNextPage(scrollY) {
			// console.log(appended);
			// console.log(theEnd);
			let resultElement = document.querySelector('#result__wrapper');
			let position = elementInViewport(resultElement, resultElement.offsetTop, resultElement.offsetHeight, 100, scrollY);
			if (position && !appended && !theEnd) {
				appended = true;
				selectedFilters['page'] = selectedFilters['page'] + 1;
				console.log('make request + append')
				makeRequest(selectedFilters, 'append');
			}
		}

		if (document.querySelector('body').classList.contains('paginate_index')) {

			var loadMoreBtn = document.querySelector('.result__container .load-more');
			if (loadMoreBtn) {
				loadMoreBtn.addEventListener('click', function (clickEvent) {
					clickEvent.preventDefault();
					loadNextPage(false);
					clickedLoadMore = true;
					this.classList.add('hide-btn');
				});
			}
		
			document.on('scroll', function (scrollEvent) {
				if (clickedLoadMore) {
					var scrollYOffset;
					if (container[0]) {
						scrollYOffset = window.pageYOffset;
					} else {
						scrollYOffset = scrollEvent.scroll.y;
					}
					loadNextPage(scrollYOffset);
				}
			})
		
			// if (document.querySelector('.paginate')) {
			// 	postType = document.querySelector('.paginate').getAttribute("data-object");
			// 	selectedFilters.data_object = postType;
			// 	$(container).on('click', '.paginate__list a', debounce(function(e) {
			// 		e.preventDefault();
			// 		console.log(selectedFilters['page']);
			// 		var pageNumber = parseInt(e.target.getAttribute('data-page'));
			// 		selectedFilters['page'] = pageNumber;
			// 		console.log(selectedFilters['page']);
			// 		makeRequest(selectedFilters, 'pages');
			// 	}, 100));
			// 	$(container).on('click', '.paginate-prev', debounce(function(e) {
			// 		e.preventDefault();
			// 		selectedFilters['page'] = selectedFilters['page'] - 1;
			// 		console.log(selectedFilters['page']);
			// 		makeRequest(selectedFilters, 'pages');
			// 	}, 100));
			// 	$(container).on('click', '.paginate-next', debounce(function(e) {
			// 		e.preventDefault();
			// 		selectedFilters['page'] = selectedFilters['page'] + 1;
			// 		console.log(selectedFilters['page']);
			// 		makeRequest(selectedFilters, 'pages');
			// 	}, 100));
			// }

		}

		function clickFilter(items) {
			let filterForPrev = '';
			items.forEach((filter, i) => {
				filter.addEventListener('click', function() {
					getSelected(items, filterForPrev);
				})
			});
		}

		function getSelected(items, filterForPrev) {
			let newClick = true;
			items.forEach((filterItem, j) => {
				console.log(filterItem)

				postType = filterItem.getAttribute("data-object");
				selectedFilters.data_object = postType;

				let filterFor = filterItem.getAttribute('data-parent');
				if (filterForPrev != filterFor) {
					newClick = true;
				}
				filterForPrev = filterFor;
				if (!selectedFilters[filterFor] || newClick) {
					selectedFilters[filterFor] = [];
					newClick = false;
				}
				if (filterItem.checked == true) {
					filterItem.parentElement.classList.add('active');
					if (selectedFilters[filterFor].indexOf(filterItem.value) == -1) {
						selectedFilters[filterFor].push(filterItem.value);
					}
				} else {
					filterItem.parentElement.classList.remove('active');
				}
				if (j == items.length - 1) {
					filterCategory(true);
				}
			})
		}

		if (cityFilter !== null) {
			cityFilter.addEventListener('change', debounce(function(event) {
				selectedFilters['page'] = 1;
				postType = this.getAttribute("data-object");
				selectedFilters.data_object = postType;
				appended = false;
				theEnd = false;

				//location
				var parentLoc = cityFilter.getAttribute("data-parent");
				var valueLoc = cityFilter.value;

				selectedFilters[parentLoc] = valueLoc;

				if (radiusFilter !== 'all') {
					//radius
					var parentRad = radiusFilter.getAttribute("data-parent");
					var valueRad = radiusFilter.value;
					
					selectedFilters[parentRad] = valueRad;
				}

				makeRequest(selectedFilters);
			}, 200));
		}

		if (radiusFilter !== 'all' && cityFilter !== '' ) {
			radiusFilter.addEventListener('change', debounce(function(event) {
				selectedFilters['page'] = 1;
				postType = this.getAttribute("data-object");
				selectedFilters.data_object = postType;
				appended = false;
				theEnd = false;

				//radius
				var parentRad = radiusFilter.getAttribute("data-parent");
				var valueRad = radiusFilter.value;
				
				selectedFilters[parentRad] = valueRad;

				makeRequest(selectedFilters);
			}, 200));
		}

		if (textFilter !== null) {
			textFilter.addEventListener('keyup', debounce(function(event) {
				selectedFilters['page'] = 1;
				var parentTax = this.getAttribute("data-parent");
				var keyUpValue = this.value;
				appended = false;
				theEnd = false;

				postType = this.getAttribute("data-object");
				selectedFilters[parentTax] = keyUpValue;
				selectedFilters.data_object = postType;

				makeRequest(selectedFilters);
			}, 200));
		}

		if (checkboxFilters !== null) {
			clickFilter(checkboxFilters);
		}

		function filterCategory() {
			console.log("filterCategory function")
			selectedFilters['page'] = 1;
			theEnd = false;
			appended = false;
			makeRequest(selectedFilters);
		}



		// if (taxonmyFilters !== null) {
		// 	for (; i < taxonmyFilters.length; i++) {
		// 		postType = taxonmyFilters[i].getAttribute("data-object");
		// 		selectedFilters.data_object = postType;
		// 		taxonmyFilters[i].addEventListener("click", debounce(function (e) {
		// 			selectedFilters['page'] = 1;
		// 			appended = false;
		// 			theEnd = false;

		// 			$(taxonmyFilters).removeClass('active');
		// 			$(this).addClass('active');

		// 			var parentTax = this.getAttribute("data-parent");
		// 			var buttonId = this.getAttribute("value");
		// 			selectedFilters[parentTax] = buttonId;

		// 			makeRequest(selectedFilters);
		// 		}, 100));
		// 	}
		// }

		// if (taxonomyFiltersSelect !== null) {
		// 	taxonomyFiltersSelect.addEventListener('change', debounce(function(event) {
		// 		selectedFilters['page'] = 1;
		// 		appended = false;
		// 		theEnd = false;
		// 		var parentTax = this.getAttribute("data-parent");
		// 		var sortValue = this.options[this.selectedIndex].value;

		// 		postType = this.getAttribute("data-object");
		// 		selectedFilters[parentTax] = sortValue;
		// 		selectedFilters.data_object = postType;

		// 		makeRequest(selectedFilters);
		// 	}, 500));
		// }

		// if (sortFilter !== null) {
		// 	sortFilter.addEventListener('change', debounce(function(event) {
		// 		selectedFilters['page'] = 1;
		// 		appended = false;
		// 		theEnd = false;
		// 		var parentTax = this.getAttribute("data-parent");
		// 		var sortValue = this.options[this.selectedIndex].value;

		// 		postType = this.getAttribute("data-object");
		// 		selectedFilters[parentTax] = sortValue;
		// 		selectedFilters.data_object = postType;

		// 		makeRequest(selectedFilters);
		// 	}, 500));
		// }

		$(function() {
			var paramFilters = [];
			// Filter based on parameters in URL
			var param_object = getUrlParameter('data_object');
			var param_uren = getUrlParameter('uren_week');
			var param_opleidingsniveaus = getUrlParameter('opleidingsniveaus');
			var param_vakgebieden = getUrlParameter('vakgebieden');
			var param_werkgevers = getUrlParameter('werkgevers');
			var param_trefwoorden = getUrlParameter('trefwoorden');
			var param_plaatsnaam = getUrlParameter('plaatsnaam');
			var param_radius = getUrlParameter('radius');
			var param_locatie = getUrlParameter('locaties');
			var param_functiegroep = getUrlParameter('functiegroep');

			console.log(param_werkgevers);

			if (param_object || param_vakgebieden || param_werkgevers || param_uren || param_opleidingsniveaus || param_trefwoorden || param_plaatsnaam || param_radius || param_locatie || param_functiegroep) {
				paramFilters.data_object = param_object;
				
				if (param_vakgebieden !== undefined) {
					// var categoriesElement = $('*[value="' + param_categories + '"]');

					paramFilters.vakgebieden = param_vakgebieden;

					// $(taxonmyFilters).removeClass('active');
					// $(categoriesElement).addClass('active');

					// if(categoriesElement[0].nodeName == "OPTION") {
					// 	$(categoriesElement).prop("selected", "selected");
					// }
				}

				if (param_werkgevers !== undefined) {
					paramFilters.werkgevers = param_werkgevers;
				}
				if (param_uren !== undefined) {
					paramFilters.uren_week = param_uren;
				}
				if (param_opleidingsniveaus !== undefined) {
					paramFilters.opleidingsniveaus = param_opleidingsniveaus;
				}
				if (param_plaatsnaam !== undefined) {
					paramFilters.plaatsnaam = param_plaatsnaam;
				}
				if (param_radius !== undefined) {
					paramFilters.radius = param_radius;
				}
				if (param_locatie !== undefined) {
					paramFilters.locaties = param_locatie;
				}
				if (param_functiegroep !== undefined) {
					paramFilters.functiegroep = param_functiegroep;
				}
				if (param_trefwoorden !== undefined) {
					paramFilters.trefwoorden = param_trefwoorden;
				}

				makeRequest(paramFilters);
			}

		});

		// Get parameters from URL
		function getUrlParameter(sParam) {
			var sPageURL = window.location.search.substring(1),
				sURLVariables = sPageURL.split('&'),
				sParameterName,
				i;

			for (i = 0; i < sURLVariables.length; i++) {
				sParameterName = sURLVariables[i].split('=');

				if (sParameterName[0] === sParam) {
					return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
				} else {

				}
			}
		}
	}

	function elementInViewport(el, top, height, offset, scrollY) {

		while (el.offsetParent) {
			el = el.offsetParent;
			top += el.offsetTop;
		}

		if (scrollY === false) {
			return true;
		} else {
			return (
				(scrollY + window.innerHeight) > (height + top - offset)
			);
		}
	}
} export {
	filter
}