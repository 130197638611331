var $ = require("../../../config/node_modules/jquery");

// THROTTLE FUNCTION
export const throttle = function (func, limit) {
    let lastFunc;
    let lastRan;
    return function () {
        const context = this;
        const args = arguments;
        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function () {
                if (Date.now() - lastRan >= limit) {
                    func.apply(context, args);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    };
};

// DEBOUNCE FUNCTION
export const debounce = function (func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export function copyLink() {
    $('.copy-link').on('click', function(e) {
        e.preventDefault();
        var href = $(this).attr('href');
        copyToClipboard(href);
    });

    const copyToClipboard = (href) => {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val(href).select();
        document.execCommand("copy");
        $temp.remove();
    }
}
export function mobileMenu() {
    $('.navbar-toggler').click(function() {
        $('.navigation').toggleClass('menu-open');
        $('body').toggleClass('lock-scroll');
    });
}