import '../../config/node_modules/regenerator-runtime/runtime';
import { copyLink, mobileMenu } from './scripts/functions';
copyLink();
import './scripts/lazyload';
import './scripts/updatecss';
import { logoSlider } from './scripts/swiper';
logoSlider();
import { filter } from './scripts/filter';
filter();
import { accordion } from './scripts/accordion';

const mediaQuery = window.matchMedia('(max-width: 1200px)')
if(mediaQuery.matches) {
    accordion();
}
const mediaQuerySmaller = window.matchMedia('(max-width: 1024px)')
if(mediaQuerySmaller.matches) {
    mobileMenu();
}


import './styles/style';