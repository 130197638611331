function inIframe() {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
}

function reloadCss() {
	for (var link of document.querySelectorAll("link[rel=stylesheet]")) {
		if (link.href.includes('cooljob-dev.mijnmandelosite.nl/theme/assets')) {
			let linkClone = link.cloneNode(true);
			linkClone.href = linkClone.href.replace(/\?.*|$/, "?" + Date.now())
			console.log(linkClone);
			link.parentNode.insertBefore(linkClone, link);
			setTimeout(function() {
				link.remove();
			}, 500);
		}
	}
}

let clicked = false;
if (inIframe()) {
	let body = document.querySelector('body');
	if (body.classList.contains('dev_mode')) {
		body.addEventListener('click', function(e) {
			if (clicked == false) {
				reloadCss()
				clicked = true;
			}
			setTimeout(function() {
				clicked = false;
			}, 1000);
		})
	}
}